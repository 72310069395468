export const trackPageViewEvent = () => {
  if (typeof window === 'undefined' || !window.dataLayer) {
    return false;
  }
  // eslint-disable-next-line no-undef
  window.dataLayer.push({
    event: 'pageView',
  });
  return true;
};
