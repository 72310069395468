import React, { useState } from 'react';

import AppContext from 'context/AppContext';

import { PageTypes } from 'helpers/constants/pages';

export default function AppProvider({
  children,
  component,
}: {
  children: React.ReactNode;
  component?: PageTypes;
}) {
  const [transparentHeader, setTransparentHeader] = useState(false);

  const handleTransparentHeader = (transparent: boolean) =>
    setTransparentHeader(transparent);

  return (
    <AppContext.Provider
      value={{
        transparentHeader,
        handleTransparentHeader,
        component,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
