import { createContext } from 'react';

import { PageTypes } from 'helpers/constants/pages';

export type AppContextType = {
  transparentHeader: boolean;
  handleTransparentHeader: (blok: any) => void;
  component?: PageTypes;
};

const AppContext = createContext<AppContextType>({
  transparentHeader: false,
  handleTransparentHeader: () => {},
  component: 'page',
});

export default AppContext;
